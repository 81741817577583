import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { standardWrapper } from "../../styles/helpers"
import Card from "./Card"
import LoadingModal from "./LoadingModal"
import LoadMoreBtn from "./LoadMoreBtn"

const getData = graphql`
  {
    posts: allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          post {
            postExcerpt
            featuredImage {
              altText
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
          title
          id
          date
          slug
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }

    category: allWpCategory {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
  }
`

const Posts = () => {
  const postsData = useStaticQuery(getData)
  const posts = postsData.posts.edges
  const DISPLAY_NUMBER = 6

  const [postsStore, setPostsStoreStore] = useState({
    max: 0,
    current: 0,
    display: [],
    loading: false,
  })

  useEffect(() => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        max: posts?.length,
        current: DISPLAY_NUMBER,
        display: posts.slice(0, DISPLAY_NUMBER),
        more: posts?.length > DISPLAY_NUMBER,
      }
    })
  }, [])

  const getMorePosts = () => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        current: prevState.current + DISPLAY_NUMBER,
        display: posts.slice(0, prevState.display.length + DISPLAY_NUMBER),
        more: posts?.length > prevState.display.length + DISPLAY_NUMBER,
        loading: false,
      }
    })
  }

  const loadMorePostsHandler = () => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        loading: true,
      }
    })

    setTimeout(() => {
      getMorePosts()
    }, 2000)
  }

  return (
    <StyledSection>
      <div className="wrapper">
        {postsStore.display.length > 0 &&
          postsStore.display.map((post, index) => {
            return <Card post={post} index={index} />
          })}
        <LoadMoreBtn
          postsStore={postsStore}
          loadMorePostsHandler={loadMorePostsHandler}
        />
      </div>
      {postsStore.loading && <LoadingModal />}
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${standardWrapper};
    justify-content: flex-start;
  }
`

export default Posts
