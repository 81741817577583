import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TitleIntro from "../components/news/TitleIntro"
import Posts from "../components/news/Posts"

const News = props => {
  const { seoInfo } = props.data
  const titleIntro = props?.data?.titleIntro?.template?.news
  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <TitleIntro data={titleIntro} />
      <Posts />
    </Layout>
  )
}

export const newsQuery = graphql`
  query newsTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    titleIntro: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_News {
          news {
            titleIntroTitle
          }
        }
      }
    }
  }
`

export default News
