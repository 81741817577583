import React from "react"
import styled from "styled-components"
import { H1GrapePurple, medWrapper } from "../../styles/helpers"

import bannerImage from "../../images/news-updates.png"

const TitleIntro = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="banner">
          <img src={bannerImage} alt={``} />
        </div>
        <div className="title">
          <h1>{data.titleIntroTitle}</h1>
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;
  padding: 20rem 0 3.5rem;

  .wrapper {
    ${medWrapper}
  }

  .banner {
    position: absolute;
    top: 2rem;
    right: 0;
    left: 0;
    width: 25rem;
    margin: auto;

    img {
      width: 100%;
    }
  }

  .title {
    width: calc(100%);
    text-align: center;

    @media (min-width: 768px) {
      width: calc(100%);
    }

    @media (min-width: 1025px) {
      width: calc(100%);
    }

    h1 {
      ${H1GrapePurple};
      margin: 0;
    }
  }
`

export default TitleIntro
